import React from 'react';
import type { GqlKbArticleRichTextInlineEntry } from '../../../../../content/types/content/kb-article';
export const renderInlineEntry = (entry: GqlKbArticleRichTextInlineEntry) => {
    const type = entry.__typename;
    switch (type) {
        case 'AnchorTarget': {
            const id = entry.id;
            return <span id={id} />;
        }
        default: {
            // This will raise a TS error if a new type is added without including a case to handle it.
            const unknownType: never = type;
            throw new Error(
                `Rendering the entry type '${unknownType}' in inline format is not supported.`
            );
        }
    }
};
