/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react';
import {
    documentToReactComponents,
    Options
} from '@contentful/rich-text-react-renderer';
import { capitalize } from 'lodash';
import type {
    KbArticleBody,
    GqlKbArticleRichTextData,
    KbStandardArticleBody
} from '../../../content/types/content/kb-article';
import {
    BLOCKS,
    type Block,
    INLINES,
    type Inline
} from '@contentful/rich-text-types';
import {
    retrieveAndRenderBlockEntry,
    retrieveAndRenderHyperlinkedEntry,
    retrieveAndRenderInlineEntry
} from './utils/retrieve-and-render-entry';
import { RENDER_TABLE_NODES } from './utils/render-content/render-table';

export const ORDERED_KB_ARTICLE_SECTIONS = [
    'summary',
    'diagnosis',
    'cause',
    'solution'
] as const;

function renderH2Heading(
    node: Block | Inline,
    children: ReactNode
): React.ReactNode {
    // `data.id` is populated by a transformation done in the loader.
    return <h2 id={node.data.id}>{children}</h2>;
}

function renderKbArticleRtfField(
    richTextData: GqlKbArticleRichTextData,
    isMigratedKbArticle = false
): React.ReactNode {
    const {
        json,
        links: {
            entries: { block = [], hyperlink = [], inline = [] }
        }
    } = richTextData;

    const blockEntryMap = new Map(block.map((entry) => [entry.sys.id, entry]));
    const hyperlinkEntryMap = new Map(
        hyperlink.map((entry) => [entry.sys.id, entry])
    );
    const inlineEntryMap = new Map(
        inline.map((entry) => [entry.sys.id, entry])
    );

    const options: Options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) =>
                retrieveAndRenderBlockEntry(blockEntryMap, node),
            [INLINES.ENTRY_HYPERLINK]: (
                node: Block | Inline,
                children: React.ReactNode
            ) =>
                retrieveAndRenderHyperlinkedEntry(
                    hyperlinkEntryMap,
                    node,
                    children
                ),
            [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) =>
                retrieveAndRenderInlineEntry(inlineEntryMap, node),
            ...RENDER_TABLE_NODES
        }
    };

    if (isMigratedKbArticle) {
        options.renderNode = {
            ...options.renderNode,
            [BLOCKS.HEADING_2]: renderH2Heading
        };
    }

    return documentToReactComponents(json, options);
}

function renderKbArticleSections(sections: KbStandardArticleBody['sections']) {
    return ORDERED_KB_ARTICLE_SECTIONS.reduce<JSX.Element[]>(
        (renderedBodyComponents, fieldName) => {
            const fieldBody = sections[fieldName];

            if (fieldBody) {
                const fieldTitle = capitalize(fieldName);
                renderedBodyComponents.push(
                    <React.Fragment key={fieldName}>
                        <h2 id={fieldName}>{fieldTitle}</h2>
                        {renderKbArticleRtfField(fieldBody)}
                    </React.Fragment>
                );
            }

            return renderedBodyComponents;
        },
        []
    );
}

interface KbArticleRtfContentProps {
    readonly articleBody: KbArticleBody;
}

const KbArticleRtfContent: React.FC<KbArticleRtfContentProps> = ({
    articleBody
}) => {
    const content =
        articleBody.type === 'KbArticleMigrated'
            ? renderKbArticleRtfField(articleBody.body, true)
            : renderKbArticleSections(articleBody.sections);

    return <div className="rtf__content">{content}</div>;
};

export default KbArticleRtfContent;
