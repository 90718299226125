import type { ReactNode } from 'react';
import type {
    GqlKbArticleRichTextBlockEntry,
    GqlKbArticleRichTextInlineEntry
} from '../../../../content/types/content/kb-article';
import type { GqlKbRichTextHyperlinkEntry } from './render-content/render-hyperlinked-entry';
import { Block, Inline } from '@contentful/rich-text-types';
import getEntryFromNode from './get-entry-from-node';
import renderHyperlinkedEntry from './render-content/render-hyperlinked-entry';
import renderBlockEntry from './render-content/render-block-entry';
import { renderInlineEntry } from './render-content/render-inline-entry';

type GqlKbRichTextCustomRenderableEntry =
    | GqlKbArticleRichTextBlockEntry
    | GqlKbRichTextHyperlinkEntry
    | GqlKbArticleRichTextInlineEntry;

export const retrieveAndRenderEntry = <
    T extends GqlKbRichTextCustomRenderableEntry
>(
    node: Block | Inline,
    entryMap: Map<string, T>,
    renderFunc: (entry: T, children?: ReactNode) => ReactNode,
    children?: ReactNode
    // eslint-disable-next-line max-params
): ReactNode => {
    const entry = getEntryFromNode(node, entryMap);

    return renderFunc(entry, children);
};

export const retrieveAndRenderBlockEntry = (
    blockEntryMap: Map<string, GqlKbArticleRichTextBlockEntry>,
    node: Block | Inline
) =>
    retrieveAndRenderEntry<GqlKbArticleRichTextBlockEntry>(
        node,
        blockEntryMap,
        (entry) => renderBlockEntry(entry)
    );

export const retrieveAndRenderHyperlinkedEntry = (
    hyperlinkEntryMap: Map<string, GqlKbRichTextHyperlinkEntry>,
    node: Block | Inline,
    children: ReactNode
) =>
    retrieveAndRenderEntry<GqlKbRichTextHyperlinkEntry>(
        node,
        hyperlinkEntryMap,
        (entry, children) => renderHyperlinkedEntry(entry, children),
        children
    );

export const retrieveAndRenderInlineEntry = (
    inlineEntryMap: Map<string, GqlKbArticleRichTextInlineEntry>,
    node: Block | Inline
) =>
    retrieveAndRenderEntry<GqlKbArticleRichTextInlineEntry>(
        node,
        inlineEntryMap,
        (entry) => renderInlineEntry(entry)
    );
